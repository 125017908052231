import React from 'react';
import Helmet from 'react-helmet';

export function GoMeta({ module }) {
  return (
    <Helmet>
      <meta http-equiv="refresh" content="0;url=https://stepwell.com" />
      <meta name="go-import" content={`stepwell.dev/${module} git https://github.com/stepwell/${module}`} />
      <meta name="go-source" content={`stepwell.dev/${module} https://github.com/stepwell/${module} https://github.com/stepwell/${module}/tree/master{/dir} https://github.com/stepwell/${module}/blob/master{/dir}/{file}#L{line}`} />
    </Helmet>
  )
}
